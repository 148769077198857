import React, { useEffect, useState } from "react";
import "./SubmissionFormReview.scss";
import { Submission } from "api";
import produce from "immer";
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import SubmissionFormHeader from "../common/header/SubmissionFormHeader";
import FieldMapper from "../common/FieldMapper";
import SubmissionFormReviewRadioButtons, {
  ReviewMode
} from "./radio-buttons/SubmissionFormReviewRadioButtons";
import { FormMode } from "../common/field-container/SubmissionFieldContainer";
import SubmissionFormReviewRejectBox from "./reject-box/SubmissionFormReviewRejectBox";
import { MutationStatus } from "@tanstack/query-core";
import ErrorBox from "common-components/error-box/error-box";
import useSubmissionFormValidation from "../common/useSubmissionFormValidation";

const namespace = "curation-submission-form-review";

type Props = {
  formMode: FormMode;
  submission: Submission | undefined;
  status: MutationStatus;
  onConfirmClick: (reviewMode: ReviewMode, newSubmission: Submission) => void;
  isLastStep: boolean;
};

export const SubmissionFormReview = (props: Props) => {
  const [submission, setSubmission] = useState<Submission>();
  const [reviewMode, setReviewMode] = useState<ReviewMode>();
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [skipApproval, setSkipApproval] = useState<boolean>(false);
  const { registerValidation, triggerValidation } =
    useSubmissionFormValidation();
  const [publishOrSubmitBtn, setPublishOrSubmitBtn] = useState<string>("Submit");

  useEffect(() => {
    if (!props.submission) {
      return;
    }

    //sort fields
    const newSubmission = produce(props.submission, draftState => {
      draftState.skipNextApproval = skipApproval;
      draftState.fieldEntries = draftState.fieldEntries.sort((fe1, fe2) => {
        const order1 = draftState?.form.fields.find(
          f => f.id === fe1.fieldId
        )?.formOrder;

        const order2 = draftState?.form.fields.find(
          f => f.id === fe2.fieldId
        )?.formOrder;

        if (order1 === undefined || order2 === undefined) {
          return 0;
        }

        return order1 - order2;
      });
    });

    if (props.submission?.altWorkflowStep !== null && Boolean(props.submission?.altWorkflowStep?.isSkippable)) {
      const val = returnNextStep(props.submission?.altWorkflowStep.id, props.submission.altWorkflowStep.allSteps, skipApproval);
      if (val === null) {
        setPublishOrSubmitBtn("Publish")
      } else {
        setPublishOrSubmitBtn("Submit")
      }
    } else {
      setPublishOrSubmitBtn("Publish")
    }

    setSubmission(newSubmission);
  }, [props.submission, skipApproval]);


  const onChangeFormMode = (reviewMode: ReviewMode) => {
    if (!submission) {
      return;
    }

    if (reviewMode === "accept" || reviewMode === "edit") {
      const newForm = produce(submission, draftState => {
        draftState.rejectReason = null;
        draftState.skipNextApproval = reviewMode === "accept" ? skipApproval : false;

        for (let i = 0; i < draftState.fieldEntries.length; i++) {
          draftState.fieldEntries[i].finalValue =
            draftState.fieldEntries[i].submittedValue;
        }
      });

      setSubmission(newForm);
    }

    setErrors(undefined);
    setReviewMode(reviewMode);
  };

  const onChangeRejectReason = (value: string) => {
    if (!submission) {
      return;
    }

    const newForm = produce(submission, draftState => {
      draftState.rejectReason = value;
    });

    setSubmission(newForm);
  };

  const onChangeField = (index: number, value: string) => {
    if (!submission) {
      return;
    }

    const newForm = produce(submission, draftState => {
      draftState.skipNextApproval = reviewMode === 'accept' ? skipApproval : false;
      draftState.fieldEntries[index].finalValue = value;
    });


    setSubmission(newForm);
  };

  const onConfirm = () => {
    if (reviewMode && submission) {
      let formErrors: string[] = [];

      if (reviewMode === "edit") {
        formErrors = triggerValidation();
        setErrors(formErrors);
      }

      if (!formErrors.length) {
        props.onConfirmClick(reviewMode, submission);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const returnNextStep = (currentStepId: number, stepObj: any, skipApproval: boolean) => {
    const currentStepObj = stepObj.find((step: { id: number; }) => step.id === currentStepId);

    if (skipApproval) {
      if (currentStepObj.next_step_id !== null && currentStepObj.next_step_id !== undefined) {
        const nextStepObj = stepObj.find((step: { id: number; }) => step.id === currentStepObj.next_step_id);
        const nexttonextStepObj = nextStepObj.next_step_id !== null ? stepObj.find((step: { id: number; }) => step.id === nextStepObj.next_step_id) : null;
        //console.log(nexttonextStepObj, "returnNexttoNextStep", stepObj, nextStepObj, currentStepId);
        return nexttonextStepObj !== null ? nexttonextStepObj.id : null;
      } else {
        return null
      }
    } else {
      if (currentStepObj.next_step_id !== null && currentStepObj.next_step_id !== undefined) {
        const nextStepObj = stepObj.find((step: { id: number; }) => step.id === currentStepObj.next_step_id);
        //console.log(nextStepObj, "returnNextStep", stepObj);
        return nextStepObj !== null ? nextStepObj.id : null;
      } else {
        return null
      }
    }
  }

  return (
    <div className={namespace}>
      <SubmissionFormHeader formMode={props.formMode} submission={submission} />
      <div className={`${namespace}-container`}>
        <Loader status={props.status}>
          <ErrorBox errors={errors} status={props.status} />
          <SubmissionFormReviewRadioButtons
            reviewMode={reviewMode}
            onChange={onChangeFormMode}
            onChangeSkipApproval={() => setSkipApproval(!skipApproval)}
            isSkippable={props.submission?.altWorkflowStep === null ? true : Boolean(props.submission?.altWorkflowStep?.isSkippable)}
            skipApproval={skipApproval}
            isDisabled={props.status === "loading"}
            isLastStep={props.isLastStep}
          />

          <SubmissionFormReviewRejectBox
            reviewMode={reviewMode}
            value={submission?.rejectReason}
            onChange={onChangeRejectReason}
          />
          <>
            {submission?.fieldEntries.map((f, i) => (
              <FieldMapper
                key={i}
                fields={submission.form.fields}
                field={f}
                index={i}
                onChange={onChangeField}
                formMode={props.formMode}
                fieldMode={reviewMode === "edit" ? "edit" : "readonly"}
                isDisabled={props.status === "loading"}
                registerValidation={registerValidation}
              />
            ))}
          </>
        </Loader>
        <Button
          label={reviewMode === "reject" ? "Confirm" : publishOrSubmitBtn}
          type="primary"
          disabled={!reviewMode || props.status === "loading"}
          onClick={onConfirm}
        />
      </div>
    </div>
  );
};

export default SubmissionFormReview;
